import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'lib-flexible'
import './utils/flexible'

import mixin from './mixin/index.vue'
Vue.mixin(mixin)

router.afterEach(() => {
  window.scrollTo(0, 0);
  document.querySelector("body").setAttribute("style", "overflow: auto !important;");
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
