<script>
export default {
  methods: {
    // 路由跳转
    jumpPage(path) {
      this.$router.push(path);
    },
    // 跳转外链
    jumpLink(url) {
      if(!url) return
      window.open(url)
    },
  },
};
</script>