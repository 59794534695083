export const zh_en = {
    login: {
        account: 'account',
        email: 'mail',
        passWord: 'password',
        singIn: 'Log in',
        register: 'Register',
        forgotPassword: 'Forgot password?',
        acceptClause: 'I agree and accept ',
        rule: 'General Terms and Regulations',
        and: ' and ',
        policy: 'Privacy policy',
        about: 'About',
        userAgreement: 'User Agreement',
        paymentTerms: 'Payment Terms',
        accountIsLocked: 'Account is locked',
        remark: 'remark',
        appeal: 'Appeal',
        appealed: 'Appealed',
        retrievePassword: 'Retrieve password',
        sendToEmail: 'send to email',
        verify: 'verify',
        setPassword: 'Set password',
        newPassword: 'new password',
        confirmPassword: 'confirm password',
        nextStep: 'Next step',
        completed: 'Completed',
        username: 'username',
        gender: 'gender',
        accountFreezeTips: 'The account has been frozen',
        identityExpiredTips: 'Identity has expired,please log in again',
        networkTimeoutTips: 'network timeout',
        networkConnectionErrorTips: 'network connection error',
        settingSucceeded: 'Setting succeeded',
        sendingSucceeded: 'Sending succeeded',
        agreeAgreement: 'Please agree to the terms agreement and privacy policy',
    },
    header: {
        featuredBrands: 'Featured Brands',
        specialPriceArea: 'Special price area',
        petArea: 'Pet area',
        petCommunity: 'Pet community',
        searchContent: 'Search content',
        blog: 'BLOG',
        switchLanguage: 'Switch language',
        english: 'English',
        chinese: 'Chinese',
        signOut: 'Are you sure you want to log out?',
        cancel: 'Cancel',
        sure: 'Sure',
    },
    footer: {
        shoppingGuide: 'Shopping guide',
        shoppingIllustrate: 'Shopping Guide',
        customerInformation: 'Customer information',
        aboutUs: 'About us',
        mobileVersion: 'Mobile version',
        mobileIos: 'iOS',
        mobileAndroid: 'Android',
        serviceHotline: 'Service Hotline',
        mail: 'Mail',
    },
    homeContent: {
        coupon: 'Coupon',
        brandHot: 'Brand products are hot',
        findYouWant: 'Find what you want quickly',
        full: 'Full',
        minus: 'minus',
        collectImmediately: 'Collect immediately',
        useNow: 'Use Now',
    },
    petCommunity: {
        sort: 'sort',
        recommend: 'recommend',
        comments: 'Most Comments',
        favorites: 'Most Favorites',
        upload: 'Latest upload'
    },
    blogRecommend: {
        featuredArticles: 'Featured Articles',
        latestArticles: 'Latest Articles',
        section: 'Section',
        page: 'page',
    },
    blogNav: {
        showAll: 'Show all',
        searchContent: 'Search content',
    },
    blogArticle: {
        relatedArticles: 'Related articles'
    },
    petAreaNav: {
        categories: 'Categories',
        allProducts: 'All products',
        priceRange: 'The price range',
        originalInterval: 'Original interval',
        lowest: 'lowest',
        highest: 'Highest',
        filter: 'filter',
        shippingMethod: 'Shipping method',
        expressDelivery: 'Express delivery',
        inStoreService: 'In-store service',
    },
    petAreaContent: {
        price: 'price',
        addToCart: 'Add to Cart',
        empty: 'No products yet',
    },
    productDetails: {
        productNumber: 'Product Number',
        classification: 'Classification',
        collect: 'Collect',
        addToCart: 'Add to Cart',
        checkoutNow: 'Checkout now',
        details: 'Details',
        productReview: 'Product review',
        praiseOf: 'Praise of',
        praise: 'Praise',
        average: 'Average',
        badReview: 'Bad review',
        allReviews: 'All reviews',
        defaultSorting: 'Default sorting',
        chronologicalOrder: 'Chronological order',
        upToDate: 'Up to date',
        previous: 'Previous',
        next: 'Next',
        tips: 'Tips',
        collectionSucceeded: 'Collection succeeded, please continue browsing!',
        viewFavorites: 'View my favorites',
        autoClose: 'Auto close after seconds',
        emptyText: 'No comment',
    },
    myCollection: {
        sort: 'sort',
        latestCollection: 'Latest Collection',
        earliestCollection: 'Earliest Collection',
        follow: 'follow',
        followed: 'followed',
        submit: 'submit',
        seeAllReviews: 'See all reviews',
        wantToSaySomething: 'want to say something...',
        tips: 'tips',
        tipsContent: 'Are you sure you are not in the collection?',
        confirm: 'confirm',
        cancel: 'cancel',
        messageText: 'Unfavorite successful',
        empty: 'No favorite post yet',
        myCollection: 'my collection',
        seeMore: 'see more',
    },
    classDetails: {
        serve: 'Serve',
        follow: 'follow',
        followed: 'followed',
        fan: 'fan',
        post: 'post',
        myCommunity: 'my community',
        wantToSaySomething: 'want to say something...',
        seeAllReviews: 'See all reviews',
        picture: 'picture',
        video: 'video',
        release: 'release',
        relatedPosts: 'Related Posts',
        submit: 'submit',
        latestUpload: 'Latest upload',
        failedToSend: 'Failed to send',
        uploadWarning: 'File upload limit exceeded',
        empty: 'No related posts yet'
    },
    myCommunity: {
        follow: 'follow',
        followed: 'followed',
        fan: 'fan',
        post: 'post',
        allPosts: 'all posts',
        allFollow: 'all follow',
        allFan: 'all fan',
        searchValue: 'Search content',
        wantToSaySomething: 'want to say something...',
        seeAllReviews: 'See all reviews',
        submit: 'submit',
        tips: 'tips',
        tipsContent: 'This operation will permanently delete the post, do you want to continue?',
        tipsContentFollow: "Are you sure you want to stop following this user?",
        confirm: 'confirm',
        cancel: 'cancel',
        messageText: 'successfully deleted',
        messageTextFollow: 'Unfollow success',
        prevPage: 'prev',
        nextPage: 'next',
        emptyPosts: "Haven't posted yet",
        emptyFan: 'no fans yet',
        emptyFollow: 'Not following other users yet'
    },
    ohterCommunity: {
        follow: 'follow',
        followed: 'followed',
        fan: 'fan',
        post: 'post',
        allPosts: 'all posts',
        searchValue: 'Search content',
        wantToSaySomething: 'want to say something...',
        seeAllReviews: 'See all reviews',
        submit: 'submit',
        emptyPosts: "Haven't posted yet",
    },
    empty: {
        text: 'No content yet',
    },
    shoppingCart: {
        shoppingCart: 'My shopping cart'
    },
    shoppingCartContent: {
        allProducts: 'All products',
        commodity: 'Commodity',
        unitPrice: 'Unit price',
        quantity: 'Quantity',
        subtotal: 'Subtotal',
        operate: 'Operate',
        inStock: 'In stock',
        outOfStock: 'Out of stock',
        delete: 'delete',
        moveToFavorites: 'Move to favorites',
        takeDown: 'Item has been discontinued',
        chosen: 'Chosen',
        deleteGoods: 'Delete the selected item',
        addToFavorites: 'Add to favorites',
        emptyCart: 'Empty cart',
        selected: 'Selected',
        items: 'Items',
        totalPrice: 'Total price:',
        discount: 'Discount:',
        toSettle: 'To settle',
        soldOut: 'Sold out',
        empty: 'No product yet',
        deleteItem: 'Delete item',
        moveToCart: 'You can select the item to move in, or delete the item',
        afterMoveDelet: 'After moving in, the selected items are not displayed in the shopping cart',
        sure: 'Sure',
        cancel: 'Cancel',
    },
    systemNav: {
        personalCenter: 'personal center',
        transactionManagement: 'Transaction management',
        myOrder: 'My Order',
        myShoppingCart: 'my shopping cart',
        myCollection: 'my collection',
        cardManagement: 'Card management',
        myCoupon: 'my coupon',
        allCoupon: 'all coupon',
        messageManagement: 'message management',
        systemNotification: 'system notification',
        personalSettings: 'Personal settings',
        personalInformation: 'personal information',
        securitySettings: 'Security Settings',
        shippingAddress: 'Shipping address',
        myCommunity: 'my community',
    },
    personalCenter: {
        accountSettings: 'account settings',
        accountSsecurity: 'account ssecurity',
        editProfile: 'Edit profile',
        myOrder: 'My Order',
        allOrders: 'all orders',
        toBePaid: 'To be paid',
        pendingReceipt: 'pending receipt',
        comment: 'comment',
        browsingHistory: 'browsing history',
        seeMore: 'see more',
        checkTheDetails: 'check the details',
        yourOrder: 'your order',
        logisticsOrderNumber: 'logistics order number',
    },
    personalInformation: {
        title: 'personal information',
        basicInformation: 'basic information',
        modifyAvatar: 'modify avatar',
        nickname: 'nickname',
        nicknamePlaceholder: 'Please enter your nickname',
        gender: 'gender',
        genderPlaceholder: 'Please select gender',
        male: 'male',
        female: 'female',
        birthday: 'birthday',
        birthdayPlaceholder: 'Please select a date',
        email: 'Email',
        bindEmail: 'unbound mailbox binding mailbox',
        emailHasBeenBound: 'Email has been bound',
        areaCode: 'area code',
        areaCodePlaceholder: 'Please select an area code',
        cellPhone: 'cell phone',
        cellPhonePlaceholder: 'Please enter phone number',
        changePhone: 'change phone',
        streetAddress: 'street address',
        streetAddressPlaceholder: 'Please enter a street address',
        submit: 'submit',
        useSystemAvatar: 'use system avatar',
        uploadTips: 'upload local pictures: (files less than 2M, supported picture formats are jpg, png, gif)',
        clickToUpload: 'click to upload',
        save: 'save',
        sendToEmail: 'send to email',
        verificationCode: 'verification code',
        verificationCodePlaceholder: 'please enter verification code',
        sentSuccessfully: 'Sent successfully',
        bindSuccessfully: 'bind successfully',
        successfullyModified: 'Successfully modified',
        dialogTitle: 'Bind mailbox'
    },
    securitySetting: {
        title: 'security settings',
        loginPassword: 'login password',
        changeLoginPassword: 'Change login password',
        emailIsBound: 'Email is bound',
        emailNBound: 'Email not bound',
        yourBoundMailboxMachine: 'Your bound mailbox machine',
        emailWarning: 'Email as your login account, please be sure to complete this binding',
        changeMailbox: 'Change mailbox',
        bindMailbox: 'Bind mailbox',
        mobilePhoneIsBound: 'Mobile phone is bound',
        mobilePhoneIsNotBound: 'mobile phone is not bound',
        yourBoundMobilePhone: 'Your bound mobile phone',
        modifyTheBoundMobilePhone: 'Modify the bound mobile phone',
        oldPassword: 'old password',
        newPassword: 'new password',
        confirmPassword: 'Confirm Password',
        changePassword: 'change Password',
        email: 'email',
        send: 'send',
        verificationCode: 'verification code',
        confirm: "confirm",
        areaCode: 'area code',
        cellPhone: 'cell phone',
        bindPhone: 'bind phone',
        oldPasswordPlaceholder: 'Please enter the original password',
        newPasswordPlaceholder: 'Please enter a new password',
        confirmPasswordPlaceholder: 'Please enter the confirmation password',
        emailPlaceholder: 'please input your email',
        verificationCodePlaceholder: 'please enter verification code',
        areaCodePlaceholder: 'Please select an area code',
        cellPhonePlaceholder: 'Please enter phone number',
        sentSuccessfully: 'Sent successfully',
        bindSuccessfully: 'bind successfully',
        SuccessfullyModified: 'Successfully modified'
    },
    confirmOrder: {
        recipientInformation: 'Recipient information',
        newShippingAddress: 'New shipping address',
        receive: 'receive',
        defaultAddress: 'Set as the default address',
        edit: 'Edit',
        delete: 'Delete',
        moreAddresses: 'More addresses',
        collapseAddress: 'Collapse address',
        paymentMethod: 'Payment method',
        delivery: 'Delivery',
        productList: 'Product list',
        backCart: 'Back to modify cart',
        commodity: 'Commodity',
        unitPrice: 'Unit price(HK$)',
        quantity: 'Quantity',
        subtotal: 'Subtotal(HK$)',
        useACoupon: 'Use a coupon',
        allCoupons: 'All coupons',
        full: 'Full',
        noEligibleProducts: 'No eligible products',
        amountCredit: 'Amount credit',
        totalProductAmount: 'Total product amount:',
        freight: 'Freight:',
        totalPayable: 'Total payable:',
        sentTo: 'Sent to:',
        readAndAgree: 'Read and agree',
        userPurchaseNotice: 'User purchase notice',
        submitOrder: 'Submit Order',
        addConsigneeInformation: 'Add consignee information',
        areaCode: 'Area code',
        receiver: 'Receiver',
        address: 'Address',
        cellphoneNumber: 'Cellphone number',
        saveInformation: 'Save consignee information',
        expressDelivery: 'Express delivery',
        inStoreService: 'In store service',
        codeTips: 'Please select the area code',
        consigneeTips: 'Please enter the consignee',
        addressTips: 'Please enter the detailed address',
        phoneTips: 'Please enter your mobile number',
        tableEmpty: 'No data',
        chooseAddressTips: 'Please select an address',
        agreementTips: 'Please read and agree to the agreement',
        discount: 'Discount:',
    },
    countDown: {
        day: 'day',
        hour: 'hour',
        minute: 'minute',
        second: 'second',
        remaining: 'Remaining',
        calculating: 'Calculating...',
    },
    myOrder: {
        myOrder: 'My Order',
        allOrders: 'All Orders',
        toBePaid: 'To be paid',
        toBeReceived: 'Goods to be received',
        toBeEvaluated: 'To be evaluated',
        numOrName: 'Order No./Commodity Name',
        search: 'search',
        all: 'All',
        threeMonths: 'Three months',
        halfAYear: 'Half a year',
        oneYear: 'One year',
        orderDetails: 'Order details',
        receiver: 'Receiver',
        amount: 'Amount',
        state: 'State',
        operate: 'Operate',
        orderNumber: 'Order number:',
        handle: 'Handle',
        waitingForPayment: 'waiting for payment',
        track: 'Track',
        waybillNumber: 'Waybill number:',
        evaluation: 'Evaluation',
        viewReviews: 'View Reviews',
        payment: 'Payment',
        confirmGoods: 'confirm the receipt of goods',
        buyAgain: 'Buy again',
        cancelOrder: 'Cancel order',
        buyNow: 'Buy now',
        previousPage: 'Previous page',
        nextPage: 'Next page',
        noOrderYet: 'No order yet',
        paymentMethod: 'Payment method',
        cancel: 'Cancel',
        sure: 'Sure',
    },
    upload: {
        picture: 'Picture',
        uploadWarning: 'File upload limit exceeded',
    },
    evaluate: {
        personalCenter: 'Personal center',
        evaluation: 'Evaluation',
        productRating: 'Product rating',
        evaluationSheet: 'Evaluation sheet',
        submit: 'Submit',
        wantToSay: 'Want to say something...',
        starsVerify: 'Please rate the item',
        contentVerify: 'Please rate the product',
        pictureVerify: 'Please upload product photos',
        reviewCompleted: 'Review completed',
        submittedSuccess: 'Submitted successfully',
        sure: 'Sure',
    },
    goodsEvaluate: {
        pageTitle: 'View Reviews',
        rating: 'Product rating',
        views: 'views',
    },
    orderDetail: {
        personalCenter: 'Personal center',
        orderNumber: 'Order number:',
        waitingForPayment: 'Waiting for payment',
        toBeDelivered: 'To be delivered',
        pendingReceipt: 'Pending receipt',
        completed: 'Completed',
        cancelled: 'Cancelled',
        evaluation: 'Evaluation',
        payment: 'Payment',
        buyNow: 'Buy now',
        submitOrder: 'Submit Order',
        cancelSuccess: 'Cancel success',
        successfulPayment: 'Successful payment',
        goodsShipped: 'Goods shipped',
        awaitingDelivery: 'Awaiting delivery',
        finish: 'Finish',
        deliveryInformation: 'Delivery information',
        receiver: 'Receiver:',
        address: 'Address:',
        cellPhone: 'Cell phone:',
        paymentInfo: 'Payment info',
        paymentMethod: 'payment method:',
        paymentTime: 'Payment time:',
        cancellationTime: 'Cancellation time:',
        reasonForCancellation: 'Reason for Cancellation:',
        commodity: 'Commodity',
        productSpecifications: 'Product specifications',
        numberOfProducts: 'Number of Products',
        amount: 'Amount',
        productNumber: 'Product Number',
        operate: 'Operate',
        totalAmount: 'Total item amount:',
        freight: 'Freight:',
        amountsPayable: 'Amounts payable:',
        confirmGoods: 'Confirm delivery',
        discount: 'Discount:',
    },
    brandClass: {
        sort: 'sort',
        recommend: 'recommend',
        favorites: 'most collections',
        maximumSales: 'maximum sales',
        keywords: 'keywords',
        myCollection: 'my collection',
        itemOnSale: 'item on sale',
        personHasFollowed: 'person has followed',
        empty: 'No search brand',
        recommendedProducts: 'recommended products'
    },
    notice: {
        mallNotice: 'Mall Notice',
        clickToViewOrderDetails: 'click to view rrder details',
        noNotice: 'no notice',
        selectAll: 'select all',
        delete: 'delete'
    },
    myCollect: {
        title: 'My Collection',
        collections: "Collections",
        favoriteBrands: 'Favorite brands',
        productInformation: 'Product information',
        soldOut: 'Sold out',
        price: 'Price',
        inventoryStatus: 'Inventory status',
        temporaryOutOfStock: 'Temporary out of stock',
        available: 'Available',
        operation: "operation",
        offShelf: 'Off shelf',
        cancelCollection: "Cancel Collection",
        nuyNow: 'Buy Now',
        empty: 'No collected goods',
        dialogContent: 'Are you sure you want to cancel the collection?',
        dialogTitle: 'tips',
        confirm: 'confirm',
        cancel: 'cancel',
        toastTitle: 'Cancel collection successfully',
        itemOnSale: 'item on sale',
        personHasFollowed: 'person has followed',
        emptyBrand: 'No collected brands',
    },
    myCoupon: {
        title: 'My coupon',
        full: 'full',
        minus: 'minus',
        expiringSoon: 'Expiring soon',
        couponNo: 'coupon No',
        use: 'use',
        noCoupon: 'No coupon'
    },
    allCoupon: {
        title: 'All coupons',
        full: 'full',
        minus: 'minus ',
        expiringSoon: 'expiring soon',
        couponNo: 'coupon No',
        noCoupon: 'No coupon',
        receive: 'receive',
        success: 'successfully received'
    },
    rule: {
        confirm: 'Confirm',
    },
    myAddress: {
        title: 'my address',
        receiver: 'Receiver',
        areaCode: 'area code',
        address: 'Address',
        cellphoneNumber: 'phone',
        defaultAddress: 'default',
        edit: 'edit',
        delete: 'delete',
        setAsDefault: 'set as Default',
        newShippingAddress: 'New address',
        saveShippingAddress: 'save address',
        eidtAddress: 'Eidt Address',
        successfullyDeleted: 'successfully Deleted',
        dialogContent: 'Are you sure you want to delete this address?',
        dialogTitle: 'tips',
        confirm: 'confirm',
        cancel: 'cancel',
    },
    paymentFail: {
        paySuccess: 'Successful payment',
        payFail: 'Payment Fail',
    },
}

export const zh_cn = {
    login: {
        account: '賬號',
        email: '郵箱',
        passWord: '密碼',
        singIn: '登錄',
        register: '註冊',
        forgotPassword: '忘記密碼？',
        acceptClause: '本人同意並接受',
        rule: '一般條款規則',
        and: '及',
        policy: '私隱政策',
        about: '關於',
        userAgreement: '用戶協議',
        paymentTerms: '支付條款',
        accountIsLocked: '帳戶已鎖定',
        remark: '備註',
        appeal: '申訴',
        appealed: '已申诉',
        retrievePassword: '找回密碼',
        sendToEmail: '發送到郵箱',
        verify: '驗證碼',
        setPassword: '设置密碼',
        newPassword: '新密碼',
        confirmPassword: '確認密碼',
        nextStep: '下一步',
        completed: '已完成',
        username: '用戶名',
        gender: '性別',
        accountFreezeTips: '賬號被凍結',
        identityExpiredTips: '身份已過期，請重新登錄',
        networkTimeoutTips: '網絡超時',
        networkConnectionErrorTips: '網絡連接錯誤',
        sendingSucceeded: '發送成功',
        settingSucceeded: '設定成功',
        agreeAgreement: '請同意條款協定及隱私政策',
    },
    header: {
        featuredBrands: '精選品牌',
        specialPriceArea: '優惠專區',
        petArea: '寵物專區',
        petCommunity: '寵物社區',
        searchContent: '搜索内容',
        blog: 'BLOG',
        switchLanguage: '切換語言',
        english: '英文',
        chinese: '中文',
        signOut: '确定要退出登录吗？',
        cancel: '取消',
        sure: '確定',
    },
    footer: {
        shoppingGuide: '購物指南',
        shoppingIllustrate: '購物說明',
        customerInformation: '客戶資訊',
        aboutUs: '關於我們',
        mobileVersion: '手機版',
        mobileIos: 'iOS',
        mobileAndroid: 'Android',
        serviceHotline: '服務熱線',
        mail: '郵箱',
    },
    homeContent: {
        coupon: '優惠券',
        brandHot: '品牌商品熱銷',
        findYouWant: '快速尋找想要的',
        full: '滿',
        minus: '減',
        collectImmediately: '立即領取',
        useNow: '立即使用',
    },
    petCommunity: {
        sort: '排序',
        recommend: '推薦',
        comments: '最多評論',
        favorites: '最多收藏',
        upload: '最新上傳'
    },
    blogRecommend: {
        featuredArticles: '精選文章',
        latestArticles: '最新文章',
        section: '第',
        page: '頁'
    },
    blogNav: {
        showAll: '顯示所有',
        searchContent: '搜索内容',
    },
    blogArticle: {
        relatedArticles: '相關文章'
    },
    petAreaNav: {
        categories: '商品分類',
        allProducts: '全部商品',
        priceRange: '價格區間',
        originalInterval: '原始區間',
        lowest: '最低',
        highest: '最高',
        filter: '篩選',
        shippingMethod: '運送方式',
        expressDelivery: '快递',
        inStoreService: '到店服务',
    },
    petAreaContent: {
        price: '价格',
        addToCart: '加入購物車',
        empty: '暫無商品',
    },
    productDetails: {
        productNumber: '商品編號',
        classification: '分類',
        collect: '收藏',
        addToCart: '加入購物車',
        checkoutNow: '立即結帳',
        details: '詳細信息',
        productReview: '商品評價',
        praiseOf: '好評度',
        praise: '好評',
        average: '中評',
        badReview: '差評',
        allReviews: '全部評價',
        defaultSorting: '默认顺序',
        chronologicalOrder: '時間排序',
        upToDate: '最新',
        previous: '上一頁',
        next: '下一頁',
        tips: '提示',
        collectionSucceeded: '收藏成功，請繼續瀏覽！',
        viewFavorites: '查看我的收藏',
        autoClose: '秒鐘後自動關閉',
        emptyText: '暫無評論',
    },
    myCollection: {
        sort: '排序',
        latestCollection: '最新收藏',
        earliestCollection: '最早收藏',
        follow: '關注',
        followed: '已關注',
        submit: '提交',
        seeAllReviews: '查看全部評論',
        wantToSaySomething: '想說點什麼...',
        tips: '提示',
        tipsContent: '確定不在收藏?',
        confirm: '確定',
        cancel: '取消',
        messageText: '取消收藏成功',
        empty: '還沒有收藏過帖子',
        myCollection: '我的收藏',
        seeMore: '查看更多',
    },
    classDetails: {
        serve: '服務',
        follow: '關注',
        followed: '已關注',
        fan: '粉絲',
        post: '貼子',
        myCommunity: '我的社區',
        wantToSaySomething: '想說點什麼...',
        seeAllReviews: '查看全部評論',
        picture: '圖片',
        video: '視頻',
        release: '發佈',
        relatedPosts: '相關貼子',
        submit: '提交',
        latestUpload: '最新上傳',
        failedToSend: '發佈成功',
        uploadWarning: '超出文件上傳數量限制',
        empty: '還沒有相關貼子'
    },
    myCommunity: {
        follow: '關注',
        followed: '已關注',
        fan: '粉絲',
        post: '貼子',
        allPosts: '全部贴子',
        allFollow: '全部关注',
        allFan: '全部粉丝',
        searchValue: '搜索內容',
        wantToSaySomething: '想說點什麼...',
        seeAllReviews: '查看全部評論',
        submit: '提交',
        tips: '提示',
        tipsContent: '此操作將永久刪除該貼, 是否繼續?',
        tipsContentFollow: "確定不再關注該用戶嗎?",
        confirm: '確定',
        cancel: '取消',
        messageText: '刪除成功',
        messageTextFollow: '取消關注成功',
        prevPage: '上一頁',
        nextPage: '下一頁',
        emptyPosts: '還沒發過貼子',
        emptyFan: '還沒有粉絲',
        emptyFollow: '還沒有關注其他用戶'
    },
    ohterCommunity: {
        follow: '關注',
        followed: '已關注',
        fan: '粉絲',
        post: '貼子',
        allPosts: '全部贴子',
        searchValue: '搜索內容',
        wantToSaySomething: '想說點什麼...',
        seeAllReviews: '查看全部評論',
        submit: '提交',
        emptyPosts: '還沒發過貼子',
    },
    empty: {
        text: '暫無內容',
    },
    shoppingCart: {
        shoppingCart: '我的購物車',
    },
    shoppingCartContent: {
        allProducts: '全部商品',
        commodity: '商品',
        unitPrice: '單價',
        quantity: '數量',
        subtotal: '小計',
        operate: '操作',
        inStock: '有貨',
        outOfStock: '缺貨',
        delete: '刪除',
        moveToFavorites: '移入收藏',
        takeDown: '商品已下架',
        chosen: '銓選',
        deleteGoods: '删除选中的商品',
        addToFavorites: '加入收藏',
        emptyCart: '清空购物车',
        selected: '已选',
        items: '件商品',
        totalPrice: '总价：',
        discount: '优惠：',
        toSettle: '去结算',
        soldOut: '已售罄',
        empty: '還沒有商品',
        deleteItem: '刪除商品',
        moveToCart: '您可以選中移入商品，或刪除商品',
        afterMoveDelet: '移入後選中商品不在購物車中顯示',
        sure: '確定',
        cancel: '取消',
    },
    systemNav: {
        personalCenter: '個人中心',
        transactionManagement: '交易管理',
        myOrder: '我的訂單',
        myShoppingCart: '我的購物車',
        myCollection: '我的收藏',
        cardManagement: '卡券管理',
        myCoupon: '我的優惠券',
        allCoupon: '優惠券列表',
        messageManagement: '消息管理',
        systemNotification: '系統通知',
        personalSettings: '個人設置',
        personalInformation: '個人資料',
        securitySettings: '安全設置',
        shippingAddress: '收貨地址',
        myCommunity: '我的社區',
    },
    personalCenter: {
        accountSettings: '帳戶設置',
        accountSsecurity: '帳戶安全',
        editProfile: '編輯個人資料',
        myOrder: '我的訂單',
        allOrders: '全部訂單',
        toBePaid: '待支付',
        pendingReceipt: '待收货',
        comment: '待評價',
        browsingHistory: '瀏覽紀錄',
        seeMore: '查看更多',
        checkTheDetails: '查看詳情',
        yourOrder: '您的訂單',
        logisticsOrderNumber: '物流訂單編號',
    },
    personalInformation: {
        title: '個人資料',
        basicInformation: '基本資料',
        modifyAvatar: '修改頭像',
        nickname: '暱稱',
        nicknamePlaceholder: '請输入暱稱',
        gender: '性別',
        genderPlaceholder: '請選擇性別',
        male: '男',
        female: '女',
        birthday: '生日',
        birthdayPlaceholder: '請選擇日期',
        email: '郵箱',
        bindEmail: '未綁定郵箱 綁定郵箱',
        emailHasBeenBound: '已綁定郵箱',
        areaCode: '區號',
        areaCodePlaceholder: '請選擇区号',
        cellPhone: '手機',
        cellPhonePlaceholder: '請輸入手機号',
        changePhone: '更改手機',
        streetAddress: '街道地址',
        streetAddressPlaceholder: '請輸入街道地址',
        submit: '提交',
        useSystemAvatar: '使用系統頭像',
        uploadTips: '上傳本地圖片：（文件小於2M,支持圖片格式為jpg，png，gif）',
        clickToUpload: '點擊上傳',
        save: '保存',
        sendToEmail: '發送到郵箱',
        verificationCode: '驗證碼',
        verificationCodePlaceholder: '請輸入驗證碼',
        sentSuccessfully: '發送成功',
        bindSuccessfully: '綁定成功',
        successfullyModified: '修改成功',
        dialogTitle: '綁定郵箱'
    },
    securitySetting: {
        title: '安全設置',
        loginPassword: '登錄密碼',
        changeLoginPassword: '修改登錄密碼',
        emailIsBound: '郵箱已綁定',
        emailNotBound: '郵箱未綁定',
        yourBoundMailboxMachine: '您已綁定的郵箱機',
        emailWarning: '郵箱作為您的登錄帳號，請務必完成此綁定',
        changeMailbox: '更換郵箱',
        bindMailbox: '綁定郵箱',
        mobilePhoneIsBound: '手機已綁定',
        mobilePhoneIsNotBound: '手機未綁定',
        yourBoundMobilePhone: '您已綁定的手機',
        modifyTheBoundMobilePhone: '修改綁定手機',
        oldPassword: '原密碼',
        newPassword: '新密碼',
        confirmPassword: '確認密碼',
        changePassword: '修改密碼',
        email: '郵箱',
        send: '發送',
        verificationCode: '驗證碼',
        confirm: "確認",
        areaCode: '區號',
        cellPhone: '手機',
        bindPhone: '綁定手機',
        oldPasswordPlaceholder: '請輸入原密碼',
        newPasswordPlaceholder: '請輸入新密碼',
        confirmPasswordPlaceholder: '請輸入確認密碼',
        emailPlaceholder: '請輸入郵箱',
        verificationCodePlaceholder: '請輸入驗證碼',
        areaCodePlaceholder: '請選擇區號',
        cellPhonePlaceholder: '請輸入手機號',
        sentSuccessfully: '發送成功',
        bindSuccessfully: '綁定成功',
        SuccessfullyModified: '修改成功'
    },
    confirmOrder: {
        recipientInformation: '收貨人信息',
        newShippingAddress: '新增收貨地址',
        receive: '收',
        defaultAddress: '設為默認地址',
        edit: '編輯',
        delete: '刪除',
        moreAddresses: '更多地址',
        collapseAddress: '收起地址',
        paymentMethod: '支付方式',
        delivery: '配送',
        productList: '商品清單',
        backCart: '返回修改購物車',
        commodity: '商品',
        unitPrice: '單價（HK$）',
        quantity: '數量',
        subtotal: '小計（HK$）',
        useACoupon: '使用優惠券',
        allCoupons: '全部优惠券',
        full: '滿',
        noEligibleProducts: '没有符合条件商品',
        amountCredit: '金额抵用',
        totalProductAmount: '總商品金額：',
        freight: '運費：',
        totalPayable: '應付總額：',
        sentTo: '寄送至：',
        readAndAgree: '已閱讀並同意',
        userPurchaseNotice: '用戶購買須知',
        submitOrder: '提交訂單',
        addConsigneeInformation: '新增收貨人信息',
        areaCode: '區號',
        receiver: '收貨人',
        address: '詳細地址',
        cellphoneNumber: '手機號碼',
        saveInformation: '保存收貨人信息',
        expressDelivery: '快遞',
        inStoreService: '到店服務',
        codeTips: '請選擇區號',
        consigneeTips: '請輸入收貨人',
        addressTips: '請輸入詳細地址',
        phoneTips: '請輸入手機號碼',
        tableEmpty: '暫無數據',
        chooseAddressTips: '請選擇地址',
        agreementTips: '請閱讀並同意協定',
        discount: '優惠：',
    },
    countDown: {
        day: '天',
        hour: '時',
        minute: '分',
        second: '秒',
        remaining: '剩餘',
        calculating: '計算中...',
    },
    myOrder: {
        myOrder: '我的訂單',
        allOrders: '全部訂單',
        toBePaid: '待支付',
        toBeReceived: '待收货',
        toBeEvaluated: '待評價',
        numOrName: '訂單編號/商品名稱',
        search: '搜索',
        all: '全部',
        threeMonths: '三個月',
        halfAYear: '半年',
        oneYear: '一年',
        orderDetails: '訂單詳情',
        receiver: '收貨人',
        amount: '金額',
        state: '狀態',
        operate: '操作',
        orderNumber: '訂單號：',
        handle: '应付',
        waitingForPayment: '等待付款',
        track: '跟蹤',
        waybillNumber: '運單號：',
        evaluation: '評價',
        viewReviews: '查看評價',
        payment: '付款',
        confirmGoods: '確認收貨',
        buyAgain: '再次購買',
        cancelOrder: '取消訂單',
        buyNow: '立即購買',
        previousPage: '上一頁',
        nextPage: '下一頁',
        noOrderYet: '暂无订单',
        paymentMethod: '支付方式',
        cancel: '取消',
        sure: '確定',
    },
    upload: {
        picture: '圖片',
        uploadWarning: '超出文件上傳數量限制',
    },
    evaluate: {
        personalCenter: '個人中心',
        evaluation: '評價',
        productRating: '商品評分',
        evaluationSheet: '評價曬單',
        submit: '提交',
        wantToSay: '想說點什麼...',
        starsVerify: '請為商品評分',
        contentVerify: '請為商品評價',
        pictureVerify: '請上傳商品照片',
        reviewCompleted: '評論已完成',
        submittedSuccess: '提交成功',
        sure: '確定',
    },
    goodsEvaluate: {
        pageTitle: '查看評價',
        rating: '商品評分',
        views: '次瀏覽',
    },
    orderDetail: {
        personalCenter: '個人中心',
        orderNumber: '訂單號：',
        waitingForPayment: '等待付款',
        toBeDelivered: '待發貨',
        pendingReceipt: '待收貨',
        completed: '已完成',
        cancelled: '已取消',
        evaluation: '評價',
        payment: '付款',
        buyNow: '立即購買',
        submitOrder: '提交訂單',
        cancelSuccess: '取消成功',
        successfulPayment: '付款成功',
        goodsShipped: '商品發貨',
        awaitingDelivery: '等待收貨',
        finish: '完成',
        deliveryInformation: '收貨信息',
        receiver: '收貨人：',
        address: '地址：',
        cellPhone: '手機：',
        paymentInfo: '付款信息',
        paymentMethod: '支付方式：',
        paymentTime: '付款時間：',
        cancellationTime: '取消時間：',
        reasonForCancellation: '取消原因：',
        commodity: '商品',
        productSpecifications: '商品規格',
        numberOfProducts: '商品數量',
        amount: '金額',
        productNumber: '商品編號',
        operate: '操作',
        totalAmount: '總商品金額：',
        freight: '運費：',
        amountsPayable: '應付金額：',
        confirmGoods: '確認收貨',
        discount: '優惠：',
    },
    brandClass: {
        sort: '排序',
        recommend: '推薦',
        favorites: '最多收藏',
        maximumSales: '最多銷量',
        keywords: '關鍵詞',
        myCollection: '我的收藏',
        itemOnSale: '款商品在售',
        personHasFollowed: '人已關注',
        empty: '暫無搜索的品牌',
        recommendedProducts: '推薦商品'
    },
    notice: {
        mallNotice: '商城通知',
        clickToViewOrderDetails: '點擊查看訂單詳情',
        noNotice: '暂无通知',
        selectAll: '全選',
        delete: '删除'
    },
    myCollect: {
        title: '我的收藏',
        collections: "收藏的商品",
        favoriteBrands: '收藏的品牌',
        productInformation: '商品信息',
        soldOut: '已售罄',
        price: '價格',
        inventoryStatus: '庫存狀態',
        temporaryOutOfStock: '暫時缺貨',
        available: '有貨',
        operation: "操作",
        offShelf: '已下架',
        cancelCollection: "取消收藏",
        nuyNow: '立即購買',
        empty: '暫無收藏的商品',
        dialogContent: '确定取消收藏吗?',
        dialogTitle: '提示',
        confirm: '确定',
        cancel: '取消',
        toastTitle: '取消收藏成功',
        itemOnSale: '款商品在售',
        personHasFollowed: '人已關注',
        emptyBrand: '暫無收藏的品牌',
    },
    myCoupon: {
        title: '我的優惠券',
        full: '滿',
        minus: '減 ',
        expiringSoon: '即將過期',
        couponNo: '券編號',
        use: '立即使用',
        noCoupon: '暂无優惠券'
    },
    allCoupon: {
        title: '全部優惠券',
        full: '滿',
        minus: '減 ',
        expiringSoon: '即將過期',
        couponNo: '券編號',
        noCoupon: '暂无優惠券',
        receive: '立即領取',
        success: '領取成功'
    },
    rule: {
        confirm: '確定'
    },
    myAddress: {
        title: '我的地址',
        receiver: '收貨人',
        areaCode: '區號',
        address: '詳細地址',
        cellphoneNumber: '手機號碼',
        defaultAddress: '默認地址',
        edit: '編輯',
        delete: '删除',
        setAsDefault: '設為默認',
        newShippingAddress: '新增收貨地址',
        saveShippingAddress: '保存收貨地址',
        eidtAddress: '編輯收貨地址',
        successfullyDeleted: '刪除成功',
        dialogContent: '您確定要刪除該地址嗎?',
        dialogTitle: '提示',
        confirm: '確定',
        cancel: '取消',
    },
    paymentFail: {
        paySuccess: '付款成功',
        payFail: '付款失敗',
    },
}
