import Vue from 'vue'
import VueRouter from 'vue-router'
import { Local } from '@/utils/storage'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => { })
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',

    component: () => import('@/views/login')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
    redirect: '/home/homeContent',
    children: [
      {
        path: 'homeContent',
        name: 'homeContent',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/home/homeContent'),
      }
    ]
  },
  {
    path: '/brands',
    name: 'brands',
    redirect: '/brands/brandClass',
    component: () => import('@/views/brands'),
    children: [
      {
        path: '/brands/brandClass',
        name: 'brandClass',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/brands/brandClass'),
      },
      {
        path: '/brands/brandsDetails',
        name: 'brandsDetails',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/brands/brandsDetails'),
      },
    ]
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/blog'),
    redirect: '/blog/blogContent',
    children: [
      {
        path: 'blogContent',
        name: 'blogContent',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/blog/blogContent'),
      },
      {
        path: 'blogArticle',
        name: 'blogArticle',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/blog/blogArticle'),
      },
    ]
  },
  {
    path: '/petCommunity',
    name: 'petCommunity',
    redirect: '/petCommunity/communityClass',
    component: () => import('@/views/petCommunity'),
    children: [
      {
        path: '/petCommunity/communityClass',
        name: 'communityClass',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/petCommunity/communityClass'),
      },
      {
        path: '/petCommunity/myCollection',
        name: 'myCollection',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/petCommunity/myCollection'),
      },
      {
        path: '/petCommunity/classDetails',
        name: 'classDetails',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/petCommunity/classDetails'),
      },
      {
        path: '/petCommunity/myCommunity',
        name: 'myCommunity',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/petCommunity/myCommunity'),
      },
      {
        path: '/petCommunity/ohterCommunity',
        name: 'ohterCommunity',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/petCommunity/ohterCommunity'),
      }
    ]
  },
  {
    path: '/petArea',
    name: 'petArea',
    component: () => import(/* webpackChunkName: "about" */ '@/views/petArea'),
    redirect: '/petArea/petAreaContent',
    children: [
      {
        path: 'petAreaContent',
        name: 'petAreaContent',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/petArea/petAreaContent'),
      },
      {
        path: 'productDetails',
        name: 'productDetails',
        meta: {
          isNeedLogin: false
        },
        component: () => import('@/views/petArea/productDetails'),
      },
    ]
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: () => import('@/views/shoppingCart'),
    redirect: '/shoppingCart/shoppingCartContent',
    children: [
      {
        path: '/shoppingCart/shoppingCartContent',
        name: 'shoppingCartContent',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/shoppingCart/shoppingCartContent'),
      },
    ]
  },
  {
    path: '/system',
    name: 'system',
    redirect: '/system/personalCenter',
    component: () => import('@/views/system'),
    children: [
      {
        path: '/system/personalCenter',
        name: 'personalCenter',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/personalCenter'),
      },
      {
        path: '/system/personalInformation',
        name: 'personalInformation',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/personalInformation'),
      },
      {
        path: '/system/securitySetting',
        name: 'securitySetting',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/securitySetting'),
      },
      {
        path: '/system/myOrder',
        name: 'myOrder',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/myOrder'),
      },
      {
        path: '/system/notice',
        name: 'notice',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/notice'),
      },
      {
        path: '/system/myCoupon',
        name: 'myCoupon',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/myCoupon'),
      },
      {
        path: '/system/allCoupon',
        name: 'allCoupon',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/allCoupon'),
      },
      {
        path: '/system/myCollect',
        name: 'myCollect',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/myCollect'),
      },
      {
        path: '/system/myAddress',
        name: 'myAddress',
        meta: {
          isNeedLogin: true
        },
        component: () => import('@/views/system/myAddress'),
      },
      {
        path: '/system/goodsEvaluate',
        name: 'goodsEvaluate',
        component: () => import('@/views/system/goodsEvaluate'),
      },
    ]
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/confirmOrder'),
  },
  {
    path: '/paymentResult/paymentSuccess',
    name: 'paymentSuccess',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/paymentResult/paymentSuccess'),
  },
  {
    path: '/paymentResult/paymentFail',
    name: 'paymentFail',
    meta: {
      isNeedLogin: true
    },
    component: () => import('@/views/paymentResult/paymentFail'),
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import('@/views/evaluate'),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/orderDetail'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 路由加载前
router.beforeEach(async (to, from, next) => {
  const token = Local.get('token');
  const { isNeedLogin } = to.meta

  if (isNeedLogin && !token) {
    next(`/login?redirect=${to.path}&params=${JSON.stringify(to.query ? to.query : to.params)}`);
  } else {
    if (token && to.path === '/login') {
      next('/home');
    } else {
      next();
    }
  }

});

export default router
