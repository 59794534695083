<template>
  <div id="app">
    <keep-alive :exclude="excludeList">
      <router-view  :key="$route.fullPath" />
    </keep-alive>
  </div>
</template>

<script>
import { Local } from "./utils/storage";

export default {
  name: "app",
  data() {
    return {
      // 不进行缓存的页面
      excludeList: [
        "shoppingCart",
        "system",
        "confirmOrder",
        "petArea",
        "brands",
        'evaluate',
        'orderDetail',
        'login',
        'blog'
      ],
    };
  },
  methods: {
    // 获取默认语言
    getLang() {
      const lang = Local.get("lang");

      this.$store.commit("SET_LANG", lang);
    },
    // 初始化
    init() {
      this.getLang();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

html,
body,
#app {
  width: 100%;
  user-select: none;

  .el-message {
    font-size: 30px;
  }
}
</style>
